.phone-box{
    width: 345px;
    border: 1px solid #ccc;
    border-radius: 15px;
    background: #fff;
    margin-bottom: 10px;
    font-size: 0;/*去除inline-block的间距*/
}
.phone-box-header{
    width: 100%;
    height: 60px;
}
.phone-box-content{
    margin: 0 auto;
    width: 320px;
    height: 500px;
    border: 1px solid #ccc;
    overflow-y: scroll;
    overflow-x: hidden;
}
/*滚动样式 开始*/
.phone-box-content::-webkit-scrollbar{
     width: 3px;
}
.phone-box-content::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 10px;
}
.phone-box-content::-webkit-scrollbar-track-piece {
    background: transparent;
    
}
/*滚动样式 结束*/
.phone-box-contain{
    margin: 0 auto;
    width: 320px;
    min-height: 470px;
    overflow: hidden;
}
.phone-box-footer{
    width: 100%;
    height: 60px;
}


/*
项目列表
*/
.ctrl-list,.ctrl-list-item{
    display: inline-block;
    width: 100%;
}
.ctrl-list-item,.property-box .ant-form-item{
    border:1px solid #ccc;
    padding:10px;
    background-color: #fff;
}
.ctrl-list-item{
    position: relative;
    margin-bottom: 10px;
    display: flex;
}
.ctrl-list-item-remove{
    position: absolute;
    top: 0;
    right: 0;
}
.ctrl-list-item-actions{
    position: absolute;
    bottom: 0;
    right: 0;
}
.ctrl-list-item-img{
    width: 100px;
    height: 100px;
    /* border: 1px solid #ccc;
    padding: 1px; */
    /* float: left; */
}
.ctrl-list-item-img img{
    width: 100%;
}
.ctrl-list-item-info{
    /* float: left; */
    /* height: 100px; */
    width: 72%;
}
.ctrl-form-item{
    /* height: 35px; */
    line-height: 35px;
    display: flex;
}
.ctrl-form-item label{
    /* float: left; */
    width: 90px;
    text-align: right;
    font-size: 0.8rem;
}
.ctrl-form-controls{
    /* float: left; */
    width:100%;
}

/*类别组件修改样式*/
.ctrl-category-content {
    display: flex;
}
.ctrl-category-tab {
    width: 170px;
}
.ctrl-category-right {
    width: 100%;
    padding-left: 15px;
}
.category-right-item {
    padding:10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    background-color: #fff;
}
.category-level2-title {
    margin-bottom: 10px;
}


/*取色模块input高度*/
.sketch-picker .flexbox-fix{line-height: 20px;}
.sketch-picker .flexbox-fix input{height: 20px !important;line-height: 20px;width: 100% !important;}
.sketch-picker .flexbox-fix span{height: 20px;line-height: 20px;padding-top: 0px !important;}