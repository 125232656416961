body{
    background-color: #a0b4c9;
    position: relative;
}
.ant-card-head-title{
    text-align: center;
}
.login-div{
    margin: auto;
    width: 400px;
    position: absolute;
    top: 39%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.logo-div{
    position: absolute;
    top: 15%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
.logo-div label{
    font-size: 1.2rem;
}