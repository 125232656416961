ul{
    margin: 0;
    padding: 0;
}
li{
    list-style: none;
}
.diy-conitem-title{
    text-align: center;
    line-height: 35px;
    font-size: 14px;
}

.diy-conitem-ad{
    height: auto;
}
.diy-conitem-ad img{
    width: 100%;
}
.diy-conitem-space{
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 0.5rem;
    color: #bbb;
}
.diy-conitem-nav{
    /* width: 100%; */
    display: inline-block;
}
.diy-conitem-nav ul{
    padding: 0;
    margin: 0;
}
.diy-conitem-nav li{
    list-style: none;
    width: 25%;
    float: left;
}
.diy-conitem-nav li img{
    width: 100%;
    /* height: 79.5px; */
}
.diy-conitem-nav li span{
    display: block;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    overflow: hidden;
}
.diy-conitem{
    width: 100%;
    display: inline-block;
    min-height: 10px;
    position: relative;
    z-index: 1;
}
.diy-conitem-action{
    position: absolute;
    top: 0;
    left: 0;
    width: 318px;
    height: 100%;
    z-index: 111;
    cursor: move;
    box-sizing: border-box;
    display: none;
}
.diy-conitem:hover .diy-conitem-action,
.diy-conitem.selected .diy-conitem-action{
    display: block;
    /* border: 2px dashed #fa0; */
    /* border:1px dashed #1890ff; */
    border:1px dashed #fa0;
}
.diy-conitem-action-btns {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 16px;
    width: 100%;
    text-align: right;
    font-size: 0.7rem;
}
.diy-conitem-btn {
    display: inline-block;
    width: 30px;
    height: 16px;
    border:0;
    padding:0;
    font-size: .5rem;
    line-height: 16px;
    background: rgba(0,0,0,.4);
    text-align: center;
    color: #fff;
    z-index: 111;
    cursor: pointer;
    margin-left: 2px;
}
.diy-conitem-image01,
.diy-conitem-image02,
.diy-conitem-image03,
.diy-conitem-image04{
    /* width: 100%; */
    display: inline-block;
}
.diy-conitem-image01 img,
.diy-conitem-image02 li img,
.diy-conitem-image03 li img,
.diy-conitem-image04 li img{
    width: 100%;
}
.diy-conitem-image02 li{
    width: 50%;
    float: left;
}
.diy-conitem-image03 li{
    width: 33.33%;
    float: left;
}
.diy-conitem-image04 li{
    width: 25%;
    float: left;
}
/* .diy-conitem-goods01{
    width: 100%;
    display: inline-block;
    font-size: 1rem;
}
.diy-conitem-goods01 .goods-left{
    display: block;
    float: left;
}
.diy-conitem-goods01 .goods-right{
    display: block;
    float: left;
}
.diy-conitem-goods01 .goods-right .goods-title{
    height: 30px;
    line-height: 30px;
} */
/*----------------商品组件-----------------*/
.diy-conitem-goods01-st01,
.diy-conitem-goods01-st02,
.diy-conitem-goods01-st03,
.diy-conitem-goods01-st04,
.diy-conitem-goods01-st05,
.diy-conitem-goods01-st06{
    display: inline-block;
}
.diy-conitem-goods01-st01 .goods01-st01-item{
    margin-bottom: 5px;
    background-color: #fff;
}
.diy-conitem-goods01-st01 .goods01-st01-img{
    width:100%;
}
.diy-conitem-goods01-st01 .goods01-st01-tit{
    width:100%;
    font-size: 13px;
    padding:0 4px;
    color: #000000;
    line-height: 20px;
    min-height: 20px;
    max-height: 40px;
    overflow: hidden;
}
.diy-conitem-goods01-st01 .goods01-st01-price{
    color: #f00;
    font-size: 13px;
    padding:0 4px;
}

/* 商品类型2 */
.diy-conitem-goods01-st02 .goods01-st02-item {
    float: left;
    width: 48.4%;
    box-sizing: border-box;
    margin-left: 0px;
    margin-bottom: 5px;
    margin: 0.8%;
    background-color: #fff;
}

.diy-conitem-goods01-st02 .goods01-st02-img {
    width: 100%;
}
.diy-conitem-goods01-st02 .goods01-st02-tit{
    width:100%;
    font-size: 13px;
    padding:0 4px;
    color: #000000;
    line-height: 20px;
    min-height: 20px;
    max-height: 40px;
    overflow: hidden;
}
.diy-conitem-goods01-st02 .goods01-st02-price{
    width:100%;
    color: #f00;
    font-size: 13px;
    padding:0 4px;
}

/*商品类型3*/
.diy-conitem-goods01-st03 .goods01-st03-item {
    float: left;
    width: 33.33333%;
    box-sizing: border-box;
    margin-left: 0px;
    margin-bottom: 5px;
    padding: 1px;
}

.diy-conitem-goods01-st03 .goods01-st03-img {
    width: 100%;
}
.diy-conitem-goods01-st03 .goods01-st03-tit{
    width:100%;
    font-size: 12px;
    padding:0 3px;
    color: #000000;
    line-height: 18px;
    min-height: 18px;
    max-height: 36px;
    overflow: hidden;
    background-color: #fff;
}
.diy-conitem-goods01-st03 .goods01-st03-price{
    width:100%;
    color: #f00;
    font-size: 13px;
    padding:0 4px;
    background-color: #fff;
}
/*商品类型4*/
.diy-conitem-goods01-st04 {
    background-color: #fff;
}
.goods01-st04-item{
    width: 96.5%;
    display: inline-block;
    margin:0 5px;
    padding:5px 0;
    border-bottom: 1px solid #e9e9e9;
}
.goods01-st04-item:last-child{
    border-bottom:0;
}
.goods01-st04-left{
    width: 36%;
    float: left;
}
.goods01-st04-img{
    width: 100%;
}
.goods01-st04-right{
    width: 64%;
    float: left;
}
.goods01-st04-tit{
    width:100%;
    font-size: 13px;
    padding:0 4px;
    color: #000000;
    line-height: 18px;
    min-height: 18px;
    max-height: 36px;
    overflow: hidden;
}
.goods01-st04-desc{
    font-size: 12px;
    color: #ccc;
    padding: 0 4px;
    line-height: 16px;
    min-height: 16px;
    max-height: 48px;
    overflow: hidden;
}
.goods01-st04-price{
    width:100%;
    color: #f00;
    font-size: 14px;
    padding:0 4px;
}
/*商品类型5*/
.goods01-st05-item{
    width: 96.5%;
    display: inline-block;
    margin:0 5px;
    padding:5px 0;
    border-bottom: 1px solid #e9e9e9;
    background-color: #fff;
}
.goods01-st05-item:last-child{
    border-bottom:0;
}
.goods01-st05-left{
    width: 23%;
    float: left;
}
.goods01-st05-img{
    width: 100%;
}
.goods01-st05-right{
    width: 77%;
    float: left;
}
.goods01-st05-tit{
    width:100%;
    font-size: 13px;
    padding:0 4px;
    color: #000000;
    line-height: 18px;
    min-height: 18px;
    max-height: 36px;
    overflow: hidden;
}
.goods01-st05-desc{
    font-size: 12px;
    color: #ccc;
    padding: 0 4px;
    line-height: 16px;
    height: 16px;
    overflow: hidden;
}
.goods01-st05-price{
    width:100%;
    color: #f00;
    font-size: 14px;
    padding:0 4px;
}

/* 商品类型6 */
.diy-conitem-goods01-st06 {
    column-count: 2;
    column-gap: 3px;
    
}
.diy-conitem-goods01-st06 .goods01-st06-item {
    break-inside: avoid; 
    box-sizing: border-box;
    margin-bottom: 5px;
    background-color: #fff;
}
.diy-conitem-goods01-st06 .goods01-st06-img {
    width: 100%;
}
.diy-conitem-goods01-st06 .goods01-st06-tit{
    width:100%;
    font-size: 13px;
    padding:0 4px;
    color: #000000;
    line-height: 20px;
    min-height: 20px;
    max-height: 40px;
    overflow: hidden;
}
.diy-conitem-goods01-st06 .goods01-st06-price{
    width:100%;
    color: #f00;
    font-size: 13px;
    padding:0 4px;
}
.diy-conitem-goods01-st06 .goods01-st06-tags {
    padding: 3px 5px 10px 5px;
}
.diy-conitem-goods01-st06 .goods01-st06-tags span {
    display:inline-block;
    font-size: 12px;
    color: #f00;
    border: 1px solid #f00;
    border-radius: 2px;
    margin: 0 3px 2px 0;
    padding: 0 3px;
}

/*----------------旅游线路组件-----------------*/
.diy-conitem-line01-st01,
.diy-conitem-line01-st02,
.diy-conitem-line01-st03,
.diy-conitem-line01-st04,
.diy-conitem-line01-st05,
.diy-conitem-line01-st06{
    display: inline-block;
}
/*线路类型1*/
.diy-conitem-line01-st01 .line01-st01-item{
    margin-bottom: 10px;
    padding-bottom: 5px;
    position: relative;
    background-color: #ffffff;
}
.diy-conitem-line01-st01 .line01-st01-img{
    width:100%;
}
.diy-conitem-line01-st01 .line01-st01-tit{
    width:100%;
    font-size: 13px;
    padding:0 4px;
    color: #000000;
    line-height: 20px;
    min-height: 20px;
    max-height: 40px;
    overflow: hidden;
}
.diy-conitem-line01-st01 .line01-st01-desc {
    font-size: 12px;
    padding:0 4px;
    color: #999;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
}
.diy-conitem-line01-st01 .line01-st01-price{
    font-size: 12px;
    padding:0 4px;
    position: absolute;
    right: 7px;
    bottom: 5px;
    background: #ff6913;
    border-radius: 5px;
    color:#fff;
}
.diy-conitem-line01-st01 .line01-st01-price dfn {
    font-style: normal;
}
.diy-conitem-line01-st01 .line01-st01-price i{
    font-style: normal;
    font-size: 16px;
}
.diy-conitem-line01-st01 .line01-st01-pic {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.diy-conitem-line01-st01 .line01-st01-setout {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: rgba(0,0,0,0.4);
    height: 22px;
    line-height: 22px;
    padding: 0 10px;
    border-radius: 11px;
    color: #fff;
    font-size: 12px;
}
.line01-tags {
    padding: 3px 5px 0px 5px;
    height: 24px;
    overflow: hidden;
}
.line01-tags span {
    display:inline-block;
    font-size: 12px;
    color: #57B5E3;
    border: 1px solid #57B5E3;
    border-radius: 2px;
    margin: 0 3px 2px 0;
    padding: 0 3px;
}

/* 线路类型2 */
.diy-conitem-line01-st02 .line01-st02-item {
    float: left;
    width: 48.4%;
    box-sizing: border-box;
    margin-left: 0px;
    margin-bottom: 5px;
    margin: 0.8%;
    position: relative;
    background-color: #fff;
}

.diy-conitem-line01-st02 .line01-st02-img {
    width: 100%;
}
.diy-conitem-line01-st02 .line01-st02-tit{
    width:100%;
    font-size: 13px;
    padding:0 4px;
    color: #000000;
    line-height: 20px;
    min-height: 20px;
    max-height: 40px;
    overflow: hidden;
}
.diy-conitem-line01-st02 .line01-st02-price{
    width:100%;
    color: #ff6913;
    font-size: 13px;
    padding:0 4px;
}
.diy-conitem-line01-st02 .line01-st02-price dfn {
    font-style: normal;
    font-size: 12px;
}
.diy-conitem-line01-st02 .line01-st02-price i {
    font-style: normal;
    font-size: 16px;
}
.diy-conitem-line01-st02 .line01-st02-price em {
    font-style: normal;
    font-size: 12px;
    color: #999;
    padding-left: 2px;
}
.diy-conitem-line01-st02 .line01-st02-setout {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.4);
    height: 22px;
    line-height: 22px;
    padding: 0 7px;
    color: #fff;
    font-size: 12px;
}

/* 线路类型3 */
.diy-conitem-line01-st03 .line01-st03-item {
    float: left;
    width: 33.33333%;
    box-sizing: border-box;
    margin-left: 0px;
    margin-bottom: 5px;
    padding: 1px;
    position: relative;
}

.diy-conitem-line01-st03 .line01-st03-img {
    width: 100%;
}
.diy-conitem-line01-st03 .line01-st03-tit{
    width:100%;
    font-size: 12px;
    padding:0 4px;
    color: #000000;
    line-height: 20px;
    min-height: 20px;
    max-height: 40px;
    overflow: hidden;
    background-color: #fff;
}
.diy-conitem-line01-st03 .line01-st03-price{
    width:100%;
    color: #ff6913;
    font-size: 13px;
    padding:0 4px;
    background-color: #fff;
}
.diy-conitem-line01-st03 .line01-st03-price dfn {
    font-style: normal;
    font-size: 10px;
}
.diy-conitem-line01-st03 .line01-st03-price i {
    font-style: normal;
    font-size: 14px;
}
.diy-conitem-line01-st03 .line01-st03-price em {
    font-style: normal;
    font-size: 10px;
    color: #999;
    padding-left: 2px;
}
.diy-conitem-line01-st03 .line01-st03-setout {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: rgba(0,0,0,0.4);
    height: 22px;
    line-height: 22px;
    padding: 0 7px;
    color: #fff;
    font-size: 12px;
}
/*线路类型4*/
.diy-conitem-line01-st04 {
    background-color: #fff;
}
.line01-st04-item{
    width: 96.5%;
    display: inline-block;
    margin:0 5px;
    padding:5px 0;
    border-bottom: 1px solid #e9e9e9;
    
}
.line01-st04-item:last-child{
    border-bottom:0;
}
.line01-st04-left{
    width: 36%;
    float: left;
    position: relative;
}
.line01-st04-left .line01-st04-setout {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.4);
    height: 22px;
    line-height: 22px;
    padding: 0 7px;
    color: #fff;
    font-size: 10px;
}
.line01-st04-img{
    width: 100%;
}
.line01-st04-right{
    width: 64%;
    float: left;
}
.line01-st04-tit{
    width:100%;
    font-size: 13px;
    padding:0 4px;
    color: #000000;
    line-height: 18px;
    min-height: 18px;
    max-height: 36px;
    overflow: hidden;
}
.line01-st04-desc{
    font-size: 12px;
    color: #ccc;
    padding: 0 4px;
    line-height: 16px;
    height: 16px;
    overflow: hidden;
}
.line01-st04-price{
    width:100%;
    color: #ff6913;
    font-size: 14px;
    padding:0 10px;
    text-align: right;
}
.line01-st04-price dfn {
    font-style: normal;
    font-size: 10px;
}
.line01-st04-price i {
    font-style: normal;
    font-size: 14px;
}
.line01-st04-price em {
    font-style: normal;
    font-size: 10px;
    color: #999;
    padding-left: 2px;
}

/*线路类型5*/
.diy-conitem-line01-st05 {
    background-color: #fff;
}
.line01-st05-item{
    width: 96.5%;
    display: inline-block;
    margin:0 5px;
    padding:5px 0;
    border-bottom: 1px solid #e9e9e9;
}
.line01-st05-item:last-child{
    border-bottom:0;
}
.line01-st05-left{
    width: 23%;
    float: left;
    position: relative;
}
.line01-st05-left .line01-st05-setout {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.4);
    height: 18px;
    line-height: 18px;
    color: #fff;
    font-size: 8px;
    width: 100%;
    text-align: center;
}
.line01-st05-img{
    width: 100%;
}
.line01-st05-right{
    width: 77%;
    float: left;
}
.line01-st05-tit{
    width:100%;
    font-size: 13px;
    padding:0 4px;
    color: #000000;
    line-height: 18px;
    min-height: 18px;
    max-height: 36px;
    overflow: hidden;
}
.line01-st05-desc{
    font-size: 12px;
    color: #ccc;
    padding: 0 4px;
    line-height: 16px;
    height: 16px;
    overflow: hidden;
}
.line01-st05-price{
    width:100%;
    color: #ff6913;
    font-size: 14px;
    padding:0 6px;
    text-align: right;
}
.line01-st05-price dfn {
    font-style: normal;
    font-size: 10px;
}
.line01-st05-price i {
    font-style: normal;
    font-size: 14px;
}
.line01-st05-price em {
    font-style: normal;
    font-size: 10px;
    color: #999;
    padding-left: 2px;
}
/* 线路类型6 */
.diy-conitem-line01-st06 {
    column-count: 2;
    column-gap: 3px;
    
}
.diy-conitem-line01-st06 .line01-st06-item {
    break-inside: avoid; 
    box-sizing: border-box;
    margin-bottom: 5px;
    position: relative;
    background-color: #fff;
}
.diy-conitem-line01-st06 .line01-st06-img {
    width: 100%;
}
.diy-conitem-line01-st06 .line01-st06-tit{
    width:100%;
    font-size: 13px;
    padding:0 4px;
    color: #000000;
    line-height: 20px;
    min-height: 20px;
    max-height: 40px;
    overflow: hidden;
}
.diy-conitem-line01-st06 .line01-st06-price{
    width:100%;
    color: #ff6913;
    font-size: 13px;
    padding:0 4px;
}
.diy-conitem-line01-st06 .line01-st06-price dfn {
    font-style: normal;
    font-size: 12px;
}
.diy-conitem-line01-st06 .line01-st06-price i {
    font-style: normal;
    font-size: 16px;
}
.diy-conitem-line01-st06 .line01-st06-price em {
    font-style: normal;
    font-size: 12px;
    color: #999;
    padding-left: 2px;
}
.diy-conitem-line01-st06 .line01-st06-setout {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.4);
    height: 22px;
    line-height: 22px;
    padding: 0 7px;
    color: #fff;
    font-size: 12px;
}

/*类别组件*/
.diy-conitem-category {
    width: 100%;
    font-size: 14px;
    background-color: #fff;
}
.category-tab {
    width: 80px;
    background-color: #f8f8f8;
    float: left;
    height: auto;
    min-width: 76px;
    min-height: 100%;
}
.category-tab li {
    height: 46px;
    line-height: 46px;
    text-align: center;
    overflow: hidden;
}
.category-tab li.cur {
    background-color: #fff;
    color: #f00;
}
.category-content {
    margin-left: 80px;
}
.category-content-item
{
    padding: 12px 7px 0;
}
.category-level3 {
    border: 0;
    font-size: 0;
    padding: 7px 5px 0;
    overflow: hidden;
    display: inline-block;
    width: 100%;
}
.category-level3 li {
    width: 32.8%;
    float: left;
    text-align: center;
    margin-bottom: 6px;
}
.category-level3 li img {
    width: 60px;
    height: 60px;
    vertical-align: middle;
}
.category-level3 li span {
    display: block;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
    overflow: hidden;
}

/*
工具栏
*/

.tool-box{
    background-color: #fff;
    width: 100%;
}

/*
属性栏
*/
.property-box{
    border: 1px solid #ccc;
    min-height: 200px;
    padding: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

